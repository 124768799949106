
import React from "react"
// import "./lesson.css"
import {
    FaClock,
  } from 'react-icons/fa'
// import Button from "./button";

// function getLangString(lang){
//     return lang === "ru" ? "Venäjäksi" : "Suomeksi";
// }

function getColorNum(category){
    let color = 1;
    if (category === "_4_6v") color = 1;
    if (category === "_7_10v") color = 22;
    if (category === "_11_15v") color = 3;
    if (category === "Aikuiset") color = 2;
    return color;
}

function Lesson(props){
    const color = getColorNum(props.category);
    const lang = props.translation.schedule[props.language];
    const category = props.translation.schedule[props.category];

    return (
        <li className={"vp-time-table-lesson mb1" + (color ? ` color-accent-${color}` : "")}>
            {/* <a href={props.email ? 'mailto:'+props.email+'?subject=Varaa paikkani '+props.day+' '+props.time+' '+category : ""} className="vp-time-table-lesson__link  df aic"> */}
            <button onClick={props.onClick} className="vp-time-table-lesson__link df aic">
                <FaClock className="vp-time-table-lesson__icon icon-lg"></FaClock>
                <div className="vp-time-table-lesson__info h4">
                    <div className="df">
                    <div className="color-text fw300 mr1">{props.time}</div><div className="fwb">{category}</div>
                    </div>
                    <div className="color-text tal fz05">
                        {lang}
                        {/* <span className="mr1">{lang}</span> 
                        <Button color={color} size="small">Varaa</Button> */}
                    </div>
                </div>
            </button>    
            {/* </a> */}
        </li>
    );
}

export default Lesson